
import { defineComponent, onMounted } from "vue";
import KTChartWidget1 from "@/components/widgets/charts/Widget1.vue";
import KTListWidget5 from "@/components/widgets/lists/Widget5.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";
import firebase from 'firebase'

export default defineComponent({
  name: "account-overview",
  components: {
    KTChartWidget1,
    KTListWidget5,
  },
  data(){
    return {
      user: null,
      activity: [],
      peer_transactions: []
    }
  },
  methods: {
    async getuser(){
      try{
        let { data } = await this.axios(`/users/${this.$route.params.uid}`)
        this.user = data.user
        this.getactivity()
      }catch(e){
        console.log(e)
      }
    },
    async kycremind(){
      try{
        useToast().info('Sending email...')
        let {data} = await this.axios.get(`/token/${this.user.uid}`)
        let credential = await firebase.auth().signInWithCustomToken(data.token)
        await credential.user.sendEmailVerification()
        useToast().success('An email has been sent to the user to verify the email address.')
        firebase.auth().signOut()
      }catch(e){
        useToast().warning(e.message)
      }
    },
    async getactivity(){
      try{
        let  { data } = await this.axios.get(`/activity/${this.$route.params.uid}`)

        //replace user variable with name
        let fixed = []
        data.activity.map(a=>{
          let activity = a
          activity.user = `${this.user.name}`
          fixed.push(activity)
        })
        this.activity = fixed
      }catch(e){
        console.log(e)
      }
    }
  },
  mounted() {
    this.getuser()
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });
  },
});
